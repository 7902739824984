import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])

const continuousCarousel = () => {

    const carousels = document.querySelectorAll('[data-continuous-investment-swiper]')

    if (!carousels) return false

    carousels.forEach(carousel => {
        let swiper = new Swiper(carousel, {
            direction: 'horizontal',
            loop: true,
            slidesPerView: 1,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            navigation: {
                nextEl: '.continuous-investment-nav.next',
                prevEl: '.continuous-investment-nav.prev',
            }
        })
    })

}

export default continuousCarousel
