import Warp from 'warpjs'

const ribbonAnimation = () => {

  const ribbon = document.querySelector('.wc-banner__ribbon')

  if ( !ribbon ) return 

  const warp = new Warp(ribbon)

  warp.interpolate(3)
  warp.transform(([ x, y ]) => [ x, y, y ])

  let offset = 0
  function animate() {
    warp.transform(([ x, y, oy ]) => [ x, oy + 4 * Math.sin(x / 64 + offset), oy ])
    offset += 0.1
    requestAnimationFrame(animate)
  }

  animate()

  
}

export default ribbonAnimation