import gsap from 'gsap';
import { ScrollTrigger } from '../libs/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

const scrollAnimation = () => {

	const items = gsap.utils.toArray('[data-fade-in]');

	items.forEach((el) => {

		gsap.from(el, {
			opacity: 0.2,
			y: 100,
			scrollTrigger: {
				trigger: el,
				start: 'top 100%',
				end: '+=275px',
				scrub: true,
			}
		});

	});
}

export default scrollAnimation;
