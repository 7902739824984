import Swiper from 'swiper';
import { forEach } from '../utils/helpers.js';

const latestNewsCarousel = () => {

  const carousel = document.querySelectorAll('[data-carousel]');

  if (!carousel) return false;

  forEach(carousel, (index, value) => {
    let swiper = new Swiper(value, {
      direction: 'horizontal',
      loop: true,
      centeredSlides: true,
      slidesPerView: 1.5,
      spaceBetween: 20,
      breakpoints: {
        1024: {
          slidesPerView: 4,
          spaceBetween: 30,
        },
      }
    });
  });



}

export default latestNewsCarousel;
