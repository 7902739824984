/**
 * forEach helper function
 * @param {*} array Indexing
 * @param {*} callback Element your looping over
 * @param {*} scope Looped functionality
 */
export function forEach(array, callback, scope) {
  for (let i = 0; i < array.length; i++) {
    callback.call(scope, i, array[i]); // passes back stuff we need
  }
}

/**
 * EqualHeight function
 * [data-equalheight="xxx"]
 */
export function equalHeight(selector) {
  var selector = selector,
    query = document.querySelectorAll(selector),
    elements = query.length,
    max = 0;
  if (elements) {
    while (elements--) {
      var element = query[elements];
      if (element.clientHeight > max) {
        max = element.clientHeight;
      }
    }
    elements = query.length;
    while (elements--) {
      var element = query[elements];
      element.style.height = max + "px";
    }
  }
}
