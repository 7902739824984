import gsap from "gsap";
import Alpine from "alpinejs";
import collapse from "@alpinejs/collapse";

const initAlpine = () => {
  window.Alpine = Alpine;
  window.gsap = gsap;

  Alpine.plugin(collapse);

  Alpine.start();
};

export default initAlpine;
