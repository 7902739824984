import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
Swiper.use([Navigation, Pagination, Autoplay]);

import { forEach } from '../utils/helpers.js';

const heroCarousel = () => {

  const carousel = document.querySelectorAll('[data-hero-carousel]');

  if (!carousel) return false;

  forEach(carousel, (index, value) => {
    let swiper = new Swiper(value, {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      autoplay: {
        delay: 6000,
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }
    });
  });

}

export default heroCarousel;
