import gsap from 'gsap'
import { Physics2DPlugin } from 'gsap/Physics2DPlugin' 
import apiFetch from '@wordpress/api-fetch'

gsap.registerPlugin(Physics2DPlugin)

class AdventCalendar {
  constructor(el) {

    this.el = el
    this.isAnsweredCorrectly
    this.userID = el.dataset.userId
    this.userIsLoggedIn = document.body.classList.contains('logged-in')
    this.quizzes = document.querySelectorAll('.advent-quiz__answers')
    this.doors = el.querySelectorAll('[data-quiz-door]')

    this.storageQuiz = localStorage.getItem('quiz')
    this.storageIsCorrect = localStorage.getItem('isCorrect')

    console.log('user id: ' + this.userID)
    console.log('user logged in: ' + this.userIsLoggedIn)

    this.quizzes.forEach(quiz => this.quizHandler(quiz, quiz.dataset.answer))

    this.doors.forEach(door => {
      door.addEventListener('click', this.doorClickHandler.bind(this, door.dataset.quizDoor))
    })

    if (this.storageQuiz && this.userIsLoggedIn) this.submitQuizFromLocalStorage()

    jQuery(document).on('gform_confirmation_loaded', function (event, formId) {
      const confirmation = document.querySelector('.register__confirmation')
      confirmation.style.display = 'block' 
    })

    this.animateSnowflakes()
    this.animateLights()
    this.downloadResults()

    document.addEventListener('keydown', (e) => {
      if (e.key == 'Escape') {
        document.querySelectorAll('.advent-quiz').forEach(el => {
          el.classList.remove('js-open')
        })
      }
    })
     
  }
  quizHandler(quiz, answer) {

    quiz.removeAttribute('data-answer')
    quiz.addEventListener('submit', (e) => {
      e.preventDefault()
      const selectedAnswer = e.target.querySelector('input:checked')

      if (selectedAnswer) {
        this.quizSubmitted(quiz, selectedAnswer.value, answer)
      } else {
        this.quizValidation(quiz)
      }
    })

  }
  quizSubmitted(quiz, selectedAnswer, answer) {

    quiz.classList.add('disabled')

    this.isAnsweredCorrectly = selectedAnswer == answer ? true : false
  
    localStorage.setItem('quiz', quiz.dataset.day)
    localStorage.setItem('isCorrect', this.isAnsweredCorrectly)

    if (this.userIsLoggedIn) {

      this.postUserQuizData(quiz.dataset.day, this.isAnsweredCorrectly).then((res) => {
        quiz.style.display = 'none'
        quiz.nextElementSibling.style.display = 'block'
        this.el.querySelector(`[data-day="${quiz.dataset.day}"]`).classList.add('opened')
        this.closeQuizWindow(quiz.dataset.day)
      })
      
    } else {
      setTimeout(() => {
        quiz.style.display = 'none'
        quiz.nextElementSibling.nextElementSibling.style.display = 'block'
      }, 600)
      const loginButton = quiz.parentElement.querySelector('.advent-quiz__login-response button')
      loginButton.addEventListener('click', this.openUserLogin.bind(this))
    }
  }
  quizValidation(quiz) {

    const submit = quiz.querySelector('.advent-quiz__submit')
    const validation = document.createElement('p')
    const validationContent = document.createTextNode('Please select an answer above.')
    const validationExists = quiz.querySelector('.quiz-validation')

    if (!validationExists) {
      validation.classList.add('quiz-validation')
      validation.appendChild(validationContent)
      quiz.insertBefore(validation, submit)
    }

    quiz.classList.add('error')
    setTimeout(() => quiz.classList.remove('error'), 800)

  }
  doorClickHandler(day) {

    const quiz = document.querySelector(`[data-quiz-page="${day}"]`)

    quiz.classList.add('js-open')

  }
  closeQuizWindow(day) {

    const quiz = document.querySelector(`[data-quiz-page="${day}"]`)
    const returnButton = quiz.querySelector('.advent-quiz__response button')

    returnButton.addEventListener('click', () => {
      quiz.classList.remove('js-open')
      setTimeout(() => quiz.remove(), 400)
    })

  }
  async postUserQuizData(day, isCorrect) {

    return apiFetch({
      path: `/wp-json/wp/v2/users/${ this.userID }`,
      method: 'POST',
      headers: {
        'X-WP-Nonce': WP.nonceRest,
      },
      data: {
        "acf": {
          "12_days_of_christmas_2023": {
            [day]: {
              "completed": true,
              "correct": isCorrect
            }
          }
        }
      },
    })

  }
  openUserLogin(e) {

    const loginScreen = document.querySelector('.register')

    loginScreen.classList.add('js-open')

    console.log(e)
    
  }
  submitQuizFromLocalStorage() {

    console.log('submitQuizFromLocalStorage') 

    localStorage.removeItem('quiz')
    localStorage.removeItem('isCorrect')
    this.el.querySelector(`[data-day="${this.storageQuiz}"]`).classList.add('opened')

    apiFetch({
      path: `/wp-json/wp/v2/users/${ this.userID }`,
      method: 'POST',
      headers: {
        'X-WP-Nonce': WP.nonceRest,
      },
      data: {
        "acf": {
          "12_days_of_christmas_2023": {
            [this.storageQuiz]: {
              "completed": true,
              "correct": this.storageIsCorrect
            }
          }
        }
      },
    })
    .then((res) => {
      
    }) 
  }
  animateSnowflakes() {

    const snowflakes = document.querySelectorAll('[data-snowflake]')

    snowflakes.forEach((snowflake, i) => {

      gsap.to(snowflake, {
        duration: 25,
        delay: i,
        repeat: -1,
        physics2D: {
          gravity: 50
        }
      })

      gsap.to(snowflake, {
        duration: 'random(5, 10)',
        x: '-=200',
        repeat: -1,
        yoyo: true,
        ease: 'sine.inOut'
      })

    })
  }
  animateLights() {

    const lightGroups = document.querySelectorAll('[data-lights]')

    lightGroups.forEach(light => {
      const amount = light.dataset.lights
      const lights = light.querySelectorAll('[data-light]')
      const tl = gsap.timeline({
        repeat: -1
      })
  
      tl.fromTo(lights, {
        filter: 'brightness(1)'
      }, {
        duration: 0.75,
        filter: `brightness(${amount})`,
        stagger: 0.08
      })
  
      tl.to(lights, {
        duration: 0.75,
        filter: 'brightness(1)',
        stagger: 0.08
      }, '-=0.8')

    })

  }
  downloadResults() {
    const button = document.querySelector('.advent__download')
    const items = document.querySelectorAll('.advent__result-item')
    const itemsArray = []

    if (!items.length) return 

    items.forEach(item => {
      itemsArray.push(item.innerHTML.split(' — '))
    })

    const csvString = [
      [
        "Number",
        "Name / Username",
        "Email Address",
      ],
      ...itemsArray.map((entry, i) => [
        i + 1,
        entry[0],
        entry[1]
      ])
    ]
    .map(e => e.join(",")) 
    .join("\n")

    const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${csvString}`)

    button.classList.remove('disabled')
    button.setAttribute('href', encodedUri)
    button.setAttribute('download', `LGH_12_Days_Christmas_Results.csv`)

  }
}

export default () => { }

const initAdventCalendar = () => {

  const adventEl = document.querySelector('.advent')

  if (adventEl) {
    new AdventCalendar(adventEl)
  }
  
}

initAdventCalendar()