import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);
import { forEach } from '../utils/helpers.js';

const portfolioCarousel = () => {

  const carousel = document.querySelectorAll('[data-portfolio-carousel]');

  var portfolioSwiper = '';

  if (!carousel) return false;

  forEach(carousel, (index, value) => {
    portfolioSwiper = new Swiper(value, {
      direction: 'horizontal',
      loop: true,
      slidesPerView: 1,
      pagination: {
        el: '.portfolio-pagination',
        type: 'bullets',
        nextEl: '.portfolio-swiper-button-next',
        prevEl: '.portfolio-swiper-button-prev',
      },
    });
  });

  const items = document.querySelectorAll('[data-scroll-to-slide]');

  if (!items) return false;

  for (const item of items) {
    item.addEventListener('click', function(e) {

      e.preventDefault();

      const target = this.getAttribute('data-scroll-to-slide');

      carousel[0].scrollIntoView({
        behavior: "smooth",
      });

      portfolioSwiper.slideTo(target);

    });
  }


}

export default portfolioCarousel;
