const navToggle = () => {

  let el = document.getElementsByClassName('site-header__nav-toggle');

  for ( var i = 0; i < el.length; i++ ) {
    el[i].addEventListener('click', function(e) {
      if ( document.body.classList.contains('nav--open') ) {
        document.body.classList.remove('nav--open');
      } else {
        document.body.classList.add('nav--open');
      }
      e.preventDefault();
    });
  }

  document.onkeydown = function(evt) {
    evt = evt || window.event;
    if ( evt.keyCode == 27 && document.body.classList.contains('nav--open') ) {
      document.body.classList.remove('nav--open');
    }
  };
}

export default navToggle;