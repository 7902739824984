import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const euroVision = () => {

  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });

  const triggerSubmission = document.querySelector('.trigger-submission');

  if (typeof(triggerSubmission) != 'undefined' && triggerSubmission != null){
    triggerSubmission.addEventListener('click', function(e) {
      const submissionContainer = document.querySelector('.submission-container');
      submissionContainer.classList.add('active');
      e.preventDefault();
    });
  }

  const triggerVotes = document.querySelectorAll('.trigger-vote');

  if (typeof(triggerVotes) != 'undefined' && triggerVotes != null){
    triggerVotes.forEach(el => {
      el.addEventListener('click', function(e) {

        const selectedEntry = el.getAttribute('data-poll-value');
        const pollSelector = document.querySelector('#input_2_3');

        pollSelector.value = selectedEntry;

        const votingContainer = document.querySelector('.voting-container');
        votingContainer.classList.add('active');
      });
    });
  }

  const closeButton = document.querySelectorAll('.close-container');

  closeButton.forEach(function( el ){
    el.addEventListener('click', function(e) {
      const customLightbox = document.querySelectorAll('.custom-lightbox');
      customLightbox.forEach(function( el ){
        el.classList.remove('active');
      });
    });
  });
}

export default euroVision;