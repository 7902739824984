import { Loader } from '@googlemaps/js-api-loader'

export default function initClubMap() {

  const mapHolder = document.querySelector('.club-info__map')

  if (mapHolder === null) return false

  const loader = new Loader({
    apiKey: "AIzaSyBWA3BrWuazx55BDZF9rfjIEgQOSzKQhMI",
    version: "weekly",
  })
  
  loader.load().then(() => {

    const data = {
      lat: parseFloat(mapHolder.dataset.lat),
      lng: parseFloat(mapHolder.dataset.lng),
      zoom: parseFloat(mapHolder.dataset.zoom)
    }

    var uluru = {
      lat: data.lat,
      lng: data.lng
    }
  
    var map = new google.maps.Map(document.getElementById('map'), {
      zoom: data.zoom,
      center: uluru
    })
  
    // var myIcon = new google.maps.MarkerImage("ADD_URL_HERE/map-marker.png", null, null, null, new google.maps.Size(29, 35))
  
    var marker = new google.maps.Marker({
      position: uluru,
      icon: myIcon,
      map: map
    })
    
  })

}