import gsap from "gsap";
import Swiper, { Navigation } from "swiper";
Swiper.use([Navigation]);

import { forEach } from "../utils/helpers.js";

/**
 * Marquee
 */
import Marquee3k from "marquee3000";
Marquee3k.init();

const hotelSpotlight = () => {
  const fadeText = document.querySelectorAll(".fade-text");
  if (typeof fadeText != "undefined" && fadeText != null) {
    const timeline = gsap.timeline();

    timeline.to(
      fadeText,
      {
        opacity: 1,
        duration: 2,
        stagger: 0.4,
      },
      "+1"
    );
  }

  const spotlightGallery = document.querySelectorAll(
    "[data-spotlight-gallery]"
  );
  if (typeof spotlightGallery != "undefined" && spotlightGallery != null) {
    forEach(spotlightGallery, (index, el) => {
      el.classList.add("swiper-container-" + index);
      const swiper = new Swiper(".swiper-container-" + index, {
        direction: "horizontal",
        loop: true,
        followFinger: false,
        allowTouchMove: false,
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    });
  }

  return;

  const activeHotels = document.querySelectorAll(".spotlight-details");
  if (typeof activeHotels != "undefined" && activeHotels != null) {
    let mapObject = document.getElementById("spotlight-map");
    forEach(activeHotels, (index, el) => {
      let hotel = el.id.replace("hotel_", "");
      if (hotel) {
        if (mapObject.getElementById(hotel)) {
          mapObject.getElementById(hotel).classList.add("active");
        }
      }
    });
  }

  const featuredHotels = document.querySelectorAll("[data-featured-hotel]");
  if (typeof featuredHotels != "undefined" && featuredHotels != null) {
    let mapObject = document.getElementById("spotlight-map");
    forEach(featuredHotels, (index, el) => {
      let hotel = el.id.replace("hotel_", "");
      mapObject.getElementById(hotel).classList.add("featured");

      let clonePin = mapObject.getElementById(hotel).cloneNode(true);
      clonePin.classList.add("ring");
      mapObject.appendChild(clonePin);

      let animation = gsap.to(mapObject.getElementById(hotel), {
        scale: 1.75,
        transformOrigin: "center center",
        opacity: 0,
        duration: 2,
        stagger: {
          each: 0.5,
          repeat: -1,
        },
      });

      // advance the time
      animation.time(2);
    });
  }

  const hotelMoreTriggers = document.querySelectorAll(
    ".spotlight-details .more-content-trigger"
  );
  if (typeof hotelMoreTriggers != "undefined" && hotelMoreTriggers != null) {
    hotelMoreTriggers.forEach((el) => {
      el.addEventListener("click", function (e) {
        this.parentNode
          .querySelector(".more-content")
          .classList.toggle("active");
      });
    });
  }
};

export default hotelSpotlight;
