const revealForm = () => {

  const revealButton = document.querySelector('.reveal-form');

  if (!revealButton) return false;

  revealButton.addEventListener('click', function(){
    document.body.classList.add('reveal-wedding-form');
  });

}

export default revealForm;
