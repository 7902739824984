const countdown  = () => {
  
  const element = document.querySelector('.wc-banner__countdown')
  const text = document.querySelector('.wc-banner__countdown-text')

  if ( !element ) return

  function updateTimer() {

    const deadline = Date.parse(element.dataset.date + ' ' + element.dataset.time)
    const current = new Date()
    const difference = deadline - current

    const days = Math.floor(difference / (1000 * 60 * 60 * 24))
    const hours = Math.floor(difference / (1000 * 60 * 60))
    const mins = Math.floor(difference / (1000 * 60))
    const secs = Math.floor(difference / 1000)

    const d = days
    const h = hours - days * 24
    const m = mins - hours * 60
    const s = secs - mins * 60

    if ( deadline > current ) {

      element.innerHTML = 
        '<p>' + d + '<span>Days</span></p>' +
        '<p>' + h + '<span>Hours</span></p>' +
        '<p>' + m + '<span>Minutes</span></p>' +
        '<p>' + s + '<span>Seconds</span></p>'
        
    } else {

      text.style.display = 'none'
      element.innerHTML = '<p>' + element.dataset.message + '</p>'

    }

  }

  updateTimer()

  setInterval(() => updateTimer(), 1000)

}

export default countdown