import Swiper, { Navigation, Pagination } from 'swiper'
Swiper.use([Navigation, Pagination])

const slidingImageGalleruy = () => {

    const carousels = document.querySelectorAll('[data-sliding-image-gallery]')

    if (!carousels) return false

    carousels.forEach(carousel => {

        let next = carousel.querySelector('.next'),
            prev = carousel.querySelector('.prev')

        new Swiper(carousel, {
            loop: true,
            slidesPerView: 'auto',
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
        })

    })

}

export default slidingImageGalleruy
