import Rellax from 'rellax'

const parallax = () => {
  const rellaxElements = document.querySelectorAll('.rellax');
  if (typeof(rellaxElements) != 'undefined' && rellaxElements != null){
    rellaxElements.forEach(el => {
      const rellax = new Rellax(el);
    });
  }
}

export default parallax