import gsap from 'gsap';
import { ScrollTrigger } from '../libs/ScrollTrigger';
import Swiper, {Navigation, A11y} from 'swiper'

gsap.registerPlugin(ScrollTrigger);

Swiper.use([Navigation]);

// import 'swiper/css'

const foryou = () => {

  banner()
  tags()
  setTimeout(() => lines(), 100);
  marquee()
  carousel()

}

const banner = () => {

  let banner = document.querySelector('[data-banner-status]')

  if ( !banner ) return false

  setTimeout(() => banner.setAttribute('data-banner-status', 'ready'), 100);

}

const tags = () => {

  let tags = document.querySelectorAll('[data-svg-element="door-tag"]')

  if ( !tags.length ) return false

  tags.forEach(tag => {

    gsap.set(tag, {
      transformOrigin: 'center top',
    })

    gsap.fromTo(tag, {
      rotation: '-30deg',
    },{
      rotation: '30deg',
      duration: 2,
      repeat: -1,
      ease: 'Power1.easeInOut',
      yoyo: true,
    })

  })

}

const lines = () => {

  let lines = document.querySelectorAll('[data-svg-element="line"]')

  if ( !lines.length ) return false

  lines.forEach(line => {

    line.style.opacity = 1

    gsap.fromTo(line, {
      strokeDashoffset: 1000,
    },{
      strokeDashoffset: 0,
      duration: line.getAttribute('data-duration'),
      ease: 'Power1.easeInOut',
      scrollTrigger: {
        trigger: line.parentElement,
        start: 'top 80%',
        // markers: true,
      }
    })

  })

}

const marquee = () => {

  let marquee = document.querySelector('[data-marquee]'),
      tags = document.querySelectorAll('[data-svg-element="marquee-tag"]')

  if ( !marquee ) return false

  gsap.set(tags, {
    transformOrigin: 'center top',
  })

  gsap.fromTo(marquee, {
    x: window.innerWidth * -0.35,
  }, {
    x: ( window.innerWidth - marquee.offsetWidth ) * 0.55,
    scrollTrigger: {
      trigger: marquee.parentElement,
      scrub: true,
      start: 'top bottom',
      end: 'bottom top',
      // markers: true,
      onUpdate: function(e) {
        let rotation = e.getVelocity() * -0.06
        gsap.to(tags, {
          rotation: Math.min(Math.max(rotation, -90), 90)
        })
      }
    }
  })

}

const carousel = () => {

  const swiper = document.querySelector('[data-options-swiper]')

  if ( !swiper ) return false

  new Swiper(swiper, {
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  })

}

export default foryou